/*
 * Estilos CSS para Sørkapp. Basados en el Framework "Base"
 * http://matthewhartman.github.io/base/
 *
 * Documentación:
 * http://matthewhartman.github.io/base/docs/
 *
 */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
body,
form,
fieldset,
legend,
input,
select,
textarea,
button {
  margin: 0;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
body {
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
  line-height: 1rem;
  color: #585858;
  font-weight: 400;
  background: #ededed;
}
p {
  margin: 0 0 1em 0;
}
a {
  color: #222965;
  background: transparent;
  text-decoration: underline;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  font-family: "Raleway", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}
h1,
.h1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 38px;
  line-height: 2.375rem;
  margin-top: .7em;
  margin-bottom: .5em;
  color: #343434;
  font-weight: 700;
}
h2,
.h2 {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 32px;
  line-height: 2rem;
  margin-top: 1.4em;
  margin-bottom: .2em;
  color: #666666;
  font-weight: 500;
}
h3,
.h3 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  margin-top: 1em;
  margin-bottom: .2em;
  color: #343434;
  font-weight: 400;
}
h4,
.h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem;
  margin-top: 1em;
  margin-bottom: .2em;
  color: #343434;
  font-weight: 400;
}
h5,
.h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  margin-top: 1em;
  margin-bottom: .1em;
  color: #343434;
  font-weight: 400;
}
h6,
.h6 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  margin-top: 1em;
  margin-bottom: .1em;
  color: #343434;
  font-weight: 400;
}
b,
strong,
.strong {
  font-weight: 700;
}
em,
.em {
  font-style: italic;
}
abbr[title],
.abbr[title] {
  border-bottom: 1px dotted;
}
dfn {
  font-style: italic;
}
small,
.small {
  font-size: 80%;
}
mark,
.mark {
  background: #ff0;
  color: #000;
}
sub,
.sub,
sup,
.sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup,
.sup {
  top: -0.5em;
}
sub,
.sub {
  bottom: -0.25em;
}
del,
.del {
  text-decoration: line-through;
}
figure {
  margin: 1em 40px;
}
hr,
.hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 1px;
  background: #eee;
  border: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
ul,
ol {
  margin: 1em 0 2em 0;
  padding: 0 0 0 40px;
}
ul li,
ol li {
  margin-bottom: 5px;
}
dl dt {
  float: left;
  width: 25%;
  display: block;
  font-weight: 400;
}
dl dd {
  overflow: hidden;
  display: block;
}
blockquote,
.blockquote {
  margin: 3em 0;
  color: #424242;
  font-style: italic;
  font-weight: 400;
}
blockquote p,
.blockquote p {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  margin-bottom: .4em;
}
blockquote cite,
.blockquote cite {
  font-size: 9px;
  font-size: 0.5625rem;
  line-height: 13px;
  line-height: 0.8125rem;
  font-weight: 700;
  font-style: normal;
  color: #777777;
}
caption {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
  text-align: left;
  padding: .5em 0;
  border-bottom: 1px solid #d7d7d7;
}
table {
  font-size: 10px;
  font-size: 0.625rem;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 1em 0;
  text-align: left;
}
table thead td,
table tbody td,
table tfoot td,
table thead th,
table tbody th,
table tfoot th {
  color: #585858;
  padding: .8em .5em;
  border-bottom: 1px solid #e9e9e9;
}
code,
kbd,
pre,
samp {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 18px;
  line-height: 1.125rem;
  word-wrap: break-word;
  font-family: 'courier new', monospace, serif;
  color: #272727;
  background-color: #f1f1f1;
  font-weight: normal;
  padding: 5px;
  white-space: pre-wrap;
}
pre {
  padding: 10px;
  overflow: auto;
  border: 1px solid #d7d7d7;
}
fieldset,
legend {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-weight: 700;
}
.form {
  padding-top: 1em;
  padding-bottom: 1em;
}
label,
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
input {
  line-height: normal;
}
.input {
  width: 100%;
}
input[type="text"],
input[type="email"],
input[type="phone"] {
  height: 36px;
  padding: 0 .4em;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
}
select {
  text-transform: none;
}
optgroup {
  font-weight: 700;
}
button {
  overflow: visible;
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.clear:before,
.clear:after,
dl:before,
dl:after {
  content: " ";
  display: table;
}
.clear:after,
dl:after {
  clear: both;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}
.row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
.container-full {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 719px) {
  .container,
  .container-full {
    width: 720px;
  }
}
@media only screen and (min-width: 979px) {
  .container,
  .container-full {
    width: 980px;
  }
}
@media only screen and (min-width: 1139px) {
  .container,
  .container-full {
    width: 1140px;
  }
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  float: left;
}
.col-1 {
  width: 8.33333333%;
}
.col-2 {
  width: 16.66666667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333333%;
}
.col-5 {
  width: 41.66666667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333333%;
}
.col-8 {
  width: 66.66666667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333333%;
}
.col-11 {
  width: 91.66666667%;
}
.col-12 {
  width: 100%;
}
.push-1 {
  left: 8.33333333%;
}
.push-2 {
  left: 16.66666667%;
}
.push-3 {
  left: 25%;
}
.push-4 {
  left: 33.33333333%;
}
.push-5 {
  left: 41.66666667%;
}
.push-6 {
  left: 50%;
}
.push-7 {
  left: 58.33333333%;
}
.push-8 {
  left: 66.66666667%;
}
.push-9 {
  left: 75%;
}
.push-10 {
  left: 83.33333333%;
}
.push-11 {
  left: 91.66666667%;
}
.pull-1 {
  left: -8.33333333%;
}
.pull-2 {
  left: -16.66666667%;
}
.pull-3 {
  left: -25%;
}
.pull-4 {
  left: -33.33333333%;
}
.pull-5 {
  left: -41.66666667%;
}
.pull-6 {
  left: -50%;
}
.pull-7 {
  left: -58.33333333%;
}
.pull-8 {
  left: -66.66666667%;
}
.pull-9 {
  left: -75%;
}
.pull-10 {
  left: -83.33333333%;
}
.pull-11 {
  left: -91.66666667%;
}
.hide-mobile {
  display: none;
}
.show-mobile {
  display: block;
}
.show-mobile-inline-block {
  display: inline-block;
}
.show-mobile-inline {
  display: inline;
}
@media only screen and (min-width: 719px) {
  .col-tablet-1,
  .col-tablet-2,
  .col-tablet-3,
  .col-tablet-4,
  .col-tablet-5,
  .col-tablet-6,
  .col-tablet-7,
  .col-tablet-8,
  .col-tablet-9,
  .col-tablet-10,
  .col-tablet-11,
  .col-tablet-12 {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left;
  }
  .col-tablet-1 {
    width: 8.33333333%;
  }
  .col-tablet-2 {
    width: 16.66666667%;
  }
  .col-tablet-3 {
    width: 25%;
  }
  .col-tablet-4 {
    width: 33.33333333%;
  }
  .col-tablet-5 {
    width: 41.66666667%;
  }
  .col-tablet-6 {
    width: 50%;
  }
  .col-tablet-7 {
    width: 58.33333333%;
  }
  .col-tablet-8 {
    width: 66.66666667%;
  }
  .col-tablet-9 {
    width: 75%;
  }
  .col-tablet-10 {
    width: 83.33333333%;
  }
  .col-tablet-11 {
    width: 91.66666667%;
  }
  .col-tablet-12 {
    width: 100%;
  }
  .push-tablet-1 {
    left: 8.33333333%;
  }
  .push-tablet-2 {
    left: 16.66666667%;
  }
  .push-tablet-3 {
    left: 25%;
  }
  .push-tablet-4 {
    left: 33.33333333%;
  }
  .push-tablet-5 {
    left: 41.66666667%;
  }
  .push-tablet-6 {
    left: 50%;
  }
  .push-tablet-7 {
    left: 58.33333333%;
  }
  .push-tablet-8 {
    left: 66.66666667%;
  }
  .push-tablet-9 {
    left: 75%;
  }
  .push-tablet-10 {
    left: 83.33333333%;
  }
  .push-tablet-11 {
    left: 91.66666667%;
  }
  .pull-tablet-1 {
    left: -8.33333333%;
  }
  .pull-tablet-2 {
    left: -16.66666667%;
  }
  .pull-tablet-3 {
    left: -25%;
  }
  .pull-tablet-4 {
    left: -33.33333333%;
  }
  .pull-tablet-5 {
    left: -41.66666667%;
  }
  .pull-tablet-6 {
    left: -50%;
  }
  .pull-tablet-7 {
    left: -58.33333333%;
  }
  .pull-tablet-8 {
    left: -66.66666667%;
  }
  .pull-tablet-9 {
    left: -75%;
  }
  .pull-tablet-10 {
    left: -83.33333333%;
  }
  .pull-tablet-11 {
    left: -91.66666667%;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: block;
  }
  .show-tablet-inline-block {
    display: inline-block;
  }
  .show-tablet-inline {
    display: inline;
  }
  .tablet-no-padding {
    padding: 0;
  }
}
@media only screen and (min-width: 979px) {
  .col-desktop-1,
  .col-desktop-2,
  .col-desktop-3,
  .col-desktop-4,
  .col-desktop-5,
  .col-desktop-6,
  .col-desktop-7,
  .col-desktop-8,
  .col-desktop-9,
  .col-desktop-10,
  .col-desktop-11,
  .col-desktop-12 {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left;
  }
  .col-desktop-1 {
    width: 8.33333333%;
  }
  .col-desktop-2 {
    width: 16.66666667%;
  }
  .col-desktop-3 {
    width: 25%;
  }
  .col-desktop-4 {
    width: 33.33333333%;
  }
  .col-desktop-5 {
    width: 41.66666667%;
  }
  .col-desktop-6 {
    width: 50%;
  }
  .col-desktop-7 {
    width: 58.33333333%;
  }
  .col-desktop-8 {
    width: 66.66666667%;
  }
  .col-desktop-9 {
    width: 75%;
  }
  .col-desktop-10 {
    width: 83.33333333%;
  }
  .col-desktop-11 {
    width: 91.66666667%;
  }
  .col-desktop-12 {
    width: 100%;
  }
  .push-desktop-1 {
    left: 8.33333333%;
  }
  .push-desktop-2 {
    left: 16.66666667%;
  }
  .push-desktop-3 {
    left: 25%;
  }
  .push-desktop-4 {
    left: 33.33333333%;
  }
  .push-desktop-5 {
    left: 41.66666667%;
  }
  .push-desktop-6 {
    left: 50%;
  }
  .push-desktop-7 {
    left: 58.33333333%;
  }
  .push-desktop-8 {
    left: 66.66666667%;
  }
  .push-desktop-9 {
    left: 75%;
  }
  .push-desktop-10 {
    left: 83.33333333%;
  }
  .push-desktop-11 {
    left: 91.66666667%;
  }
  .pull-desktop-1 {
    left: -8.33333333%;
  }
  .pull-desktop-2 {
    left: -16.66666667%;
  }
  .pull-desktop-3 {
    left: -25%;
  }
  .pull-desktop-4 {
    left: -33.33333333%;
  }
  .pull-desktop-5 {
    left: -41.66666667%;
  }
  .pull-desktop-6 {
    left: -50%;
  }
  .pull-desktop-7 {
    left: -58.33333333%;
  }
  .pull-desktop-8 {
    left: -66.66666667%;
  }
  .pull-desktop-9 {
    left: -75%;
  }
  .pull-desktop-10 {
    left: -83.33333333%;
  }
  .pull-desktop-11 {
    left: -91.66666667%;
  }
  .hide-desktop {
    display: none;
  }
  .show-desktop {
    display: block;
  }
  .show-desktop-inline-block {
    display: inline-block;
  }
  .show-desktop-inline {
    display: inline;
  }
  .desktop-no-padding {
    padding: 0;
  }
}
@media only screen and (min-width: 1139px) {
  .col-hd-1,
  .col-hd-2,
  .col-hd-3,
  .col-hd-4,
  .col-hd-5,
  .col-hd-6,
  .col-hd-7,
  .col-hd-8,
  .col-hd-9,
  .col-hd-10,
  .col-hd-11,
  .col-hd-12 {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left;
  }
  .col-hd-1 {
    width: 8.33333333%;
  }
  .col-hd-2 {
    width: 16.66666667%;
  }
  .col-hd-3 {
    width: 25%;
  }
  .col-hd-4 {
    width: 33.33333333%;
  }
  .col-hd-5 {
    width: 41.66666667%;
  }
  .col-hd-6 {
    width: 50%;
  }
  .col-hd-7 {
    width: 58.33333333%;
  }
  .col-hd-8 {
    width: 66.66666667%;
  }
  .col-hd-9 {
    width: 75%;
  }
  .col-hd-10 {
    width: 83.33333333%;
  }
  .col-hd-11 {
    width: 91.66666667%;
  }
  .col-hd-12 {
    width: 100%;
  }
  .push-hd-1 {
    left: 8.33333333%;
  }
  .push-hd-2 {
    left: 16.66666667%;
  }
  .push-hd-3 {
    left: 25%;
  }
  .push-hd-4 {
    left: 33.33333333%;
  }
  .push-hd-5 {
    left: 41.66666667%;
  }
  .push-hd-6 {
    left: 50%;
  }
  .push-hd-7 {
    left: 58.33333333%;
  }
  .push-hd-8 {
    left: 66.66666667%;
  }
  .push-hd-9 {
    left: 75%;
  }
  .push-hd-10 {
    left: 83.33333333%;
  }
  .push-hd-11 {
    left: 91.66666667%;
  }
  .pull-hd-1 {
    left: -8.33333333%;
  }
  .pull-hd-2 {
    left: -16.66666667%;
  }
  .pull-hd-3 {
    left: -25%;
  }
  .pull-hd-4 {
    left: -33.33333333%;
  }
  .pull-hd-5 {
    left: -41.66666667%;
  }
  .pull-hd-6 {
    left: -50%;
  }
  .pull-hd-7 {
    left: -58.33333333%;
  }
  .pull-hd-8 {
    left: -66.66666667%;
  }
  .pull-hd-9 {
    left: -75%;
  }
  .pull-hd-10 {
    left: -83.33333333%;
  }
  .pull-hd-11 {
    left: -91.66666667%;
  }
  .hide-hd {
    display: none;
  }
  .show-hd {
    display: block;
  }
  .show-hd-inline-block {
    display: inline-block;
  }
  .show-hd-inline {
    display: inline;
  }
  .hd-no-padding {
    padding: 0;
  }
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.no-float {
  float: none;
}
.no-background {
  background: transparent;
}
.no-border {
  border: 0;
}
.font-thin {
  font-weight: 100;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-ultra-bold {
  font-weight: 900;
}
.font-normal {
  font-style: normal;
}
.font-italic {
  font-style: italic;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.static {
  position: static;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center-element {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-unstyled li {
  margin: 0;
  padding: 0;
}
.list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-inline li {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.img-left {
  float: left;
  margin-right: 20px;
}
.img-right {
  float: right;
  margin-left: 20px;
}
.field-group {
  padding-top: .5em;
  padding-bottom: .5em;
  overflow: hidden;
}
.field-group label {
  padding-top: 8px;
}
.field {
  width: 100%;
}
.button {
  border: 1px solid #d7d7d7;
  background-color: #f3f3f3;
  line-height: normal;
  padding: .5em 1em;
  text-decoration: none;
  color: #363636;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.button:hover {
  background: #f9f9f9;
}
.button:active {
  background: #fff;
}
.button-link {
  color: #222965;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
}
.button-link:hover {
  text-decoration: none;
}
.button-link:active {
  outline: 0;
}
.disabled {
  pointer-events: none;
  opacity: .5;
}
.select {
  border: 1px solid #ccc;
  overflow: hidden;
  height: 36px;
  line-height: 32px;
  position: relative;
  display: block;
}
.select:before {
  content: "";
  border: 6px solid transparent;
  border-top-color: #676767;
  top: 50%;
  right: 10px;
  margin-top: -3px;
  pointer-events: none;
  position: absolute;
}
.select select {
  -webkit-appearance: none;
  width: 110%;
  height: 36px;
  padding: 0 .4em;
  line-height: normal;
  border: 0;
  background: transparent;
  display: block;
}
.select select:focus {
  outline: 0;
}
.checkbox,
.radio {
  display: inline-block;
  position: relative;
  margin-top: .5em;
  margin-bottom: .5em;
}
.checkbox label,
.radio label {
  padding-left: 20px;
  padding-top: 0;
  display: inline-block;
}
.checkbox input[type="checkbox"],
.radio input[type="checkbox"],
.checkbox input[type="radio"],
.radio input[type="radio"] {
  position: absolute;
  top: 4px;
  left: 0;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent;
    color: #000;
    box-shadow: none;
    text-shadow: none;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    max-width: 100%;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}
@font-face {
  font-family: 'custom-icons';
  src: url('/fonts/custom-icons.eot?scbg9z');
  src: url('/fonts/custom-icons.eot?#iefixscbg9z') format('embedded-opentype'), url('/fonts/custom-icons.woff?scbg9z') format('woff'), url('/fonts/custom-icons.ttf?scbg9z') format('truetype'), url('/fonts/custom-icons.svg?scbg9z#custom-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: "custom-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.icon-alert:before {
  content: "\e600";
}
.icon-arrowdown:before {
  content: "\e601";
}
.icon-arrowleft:before {
  content: "\e602";
}
.icon-arrowright:before {
  content: "\e603";
}
.icon-arrowup:before {
  content: "\e604";
}
.icon-barchart:before {
  content: "\e605";
}
.icon-info:before {
  content: "\e60b";
}
.icon-tasks:before {
  content: "\e60f";
}
.icon-timeline:before {
  content: "\e610";
}
.icon-tools:before {
  content: "\e611";
}
.icon-start:before {
  content: "\e61c";
}
.icon-start-current:before {
  content: "\e616";
}
.icon-desktop:before {
  content: "\e61d";
}
.icon-desktop-current:before {
  content: "\e618";
}
.icon-controls:before {
  content: "\e620";
}
.icon-controls-current:before {
  content: "\e614";
}
.icon-content:before {
  content: "\e61e";
}
.icon-popularity:before {
  content: "\e613";
}
.icon-popularity-current:before {
  content: "\e61f";
}
.icon-social:before {
  content: "\e619";
}
.icon-social-current:before {
  content: "\e615";
}
.icon-mobile:before {
  content: "\e621";
}
.icon-mobile-current:before {
  content: "\e617";
}
.icon-eye:before {
  content: "\e000";
}
.icon-paper-clip:before {
  content: "\e001";
}
.icon-mail:before {
  content: "\e002";
}
.icon-toggle:before {
  content: "\e003";
}
.icon-layout:before {
  content: "\e004";
}
.icon-link:before {
  content: "\e005";
}
.icon-bell:before {
  content: "\e006";
}
.icon-lock:before {
  content: "\e007";
}
.icon-unlock:before {
  content: "\e008";
}
.icon-ribbon:before {
  content: "\e009";
}
.icon-image:before {
  content: "\e010";
}
.icon-signal:before {
  content: "\e011";
}
.icon-target:before {
  content: "\e012";
}
.icon-clipboard:before {
  content: "\e013";
}
.icon-clock:before {
  content: "\e014";
}
.icon-watch:before {
  content: "\e015";
}
.icon-air-play:before {
  content: "\e016";
}
.icon-camera:before {
  content: "\e017";
}
.icon-video:before {
  content: "\e018";
}
.icon-disc:before {
  content: "\e019";
}
.icon-printer:before {
  content: "\e020";
}
.icon-monitor:before {
  content: "\e021";
}
.icon-server:before {
  content: "\e022";
}
.icon-cog:before {
  content: "\e023";
}
.icon-heart:before {
  content: "\e024";
}
.icon-paragraph:before {
  content: "\e025";
}
.icon-align-justify:before {
  content: "\e026";
}
.icon-align-left:before {
  content: "\e027";
}
.icon-align-center:before {
  content: "\e028";
}
.icon-align-right:before {
  content: "\e029";
}
.icon-book:before {
  content: "\e030";
}
.icon-layers:before {
  content: "\e031";
}
.icon-stack:before {
  content: "\e032";
}
.icon-stack-2:before {
  content: "\e033";
}
.icon-paper:before {
  content: "\e034";
}
.icon-paper-stack:before {
  content: "\e035";
}
.icon-search2:before {
  content: "\e036";
}
.icon-zoom-in:before {
  content: "\e037";
}
.icon-zoom-out:before {
  content: "\e038";
}
.icon-reply:before {
  content: "\e039";
}
.icon-circle:before {
  content: "\e0a7";
}
.icon-circle-plus:before {
  content: "\e0a8";
}
.icon-circle-plus-inverse:before {
  content: "\e040";
}
.icon-circle-minus:before {
  content: "\e0a9";
}
.icon-circle-minus-inverse:before {
  content: "\e041";
}
.icon-circle-check:before {
  content: "\e0aa";
}
.icon-circle-check-inverse:before {
  content: "\e0ab";
}
.icon-circle-check-inverse2:before {
  content: "\e042";
}
.icon-circle-cross:before {
  content: "\e0ac";
}
.icon-circle-cross-inverse:before {
  content: "\e0ad";
}
.icon-circle-cross-inverse2:before {
  content: "\e043";
}
.icon-square:before {
  content: "\e044";
}
.icon-square-plus:before {
  content: "\e0ae";
}
.icon-square-plus-inverse:before {
  content: "\e0af";
}
.icon-square-minus:before {
  content: "\e0b0";
}
.icon-square-minus-inverse:before {
  content: "\e045";
}
.icon-square-check:before {
  content: "\e0b1";
}
.icon-square-check-inverse:before {
  content: "\e0b2";
}
.icon-square-check-inverse2:before {
  content: "\e046";
}
.icon-square-cross:before {
  content: "\e0b3";
}
.icon-square-cross-inverse:before {
  content: "\e0b4";
}
.icon-square-cross-inverse2:before {
  content: "\e047";
}
.icon-microphone:before {
  content: "\e048";
}
.icon-record:before {
  content: "\e049";
}
.icon-skip-back:before {
  content: "\e050";
}
.icon-rewind:before {
  content: "\e051";
}
.icon-play:before {
  content: "\e052";
}
.icon-pause:before {
  content: "\e053";
}
.icon-stop:before {
  content: "\e054";
}
.icon-fast-forward:before {
  content: "\e055";
}
.icon-skip-forward:before {
  content: "\e056";
}
.icon-shuffle:before {
  content: "\e057";
}
.icon-repeat:before {
  content: "\e058";
}
.icon-folder:before {
  content: "\e059";
}
.icon-umbrella:before {
  content: "\e060";
}
.icon-moon:before {
  content: "\e061";
}
.icon-thermometer:before {
  content: "\e062";
}
.icon-drop:before {
  content: "\e063";
}
.icon-sun:before {
  content: "\e064";
}
.icon-cloud:before {
  content: "\e065";
}
.icon-cloud-upload:before {
  content: "\e066";
}
.icon-cloud-download:before {
  content: "\e067";
}
.icon-upload:before {
  content: "\e068";
}
.icon-download:before {
  content: "\e069";
}
.icon-location:before {
  content: "\e070";
}
.icon-location-2:before {
  content: "\e071";
}
.icon-map:before {
  content: "\e072";
}
.icon-battery:before {
  content: "\e073";
}
.icon-head:before {
  content: "\e074";
}
.icon-briefcase:before {
  content: "\e075";
}
.icon-speech-bubble:before {
  content: "\e076";
}
.icon-anchor:before {
  content: "\e077";
}
.icon-globe:before {
  content: "\e078";
}
.icon-box:before {
  content: "\e079";
}
.icon-reload:before {
  content: "\e080";
}
.icon-share:before {
  content: "\e081";
}
.icon-marquee:before {
  content: "\e082";
}
.icon-marquee-plus:before {
  content: "\e083";
}
.icon-marquee-minus:before {
  content: "\e084";
}
.icon-tag:before {
  content: "\e085";
}
.icon-power:before {
  content: "\e086";
}
.icon-command:before {
  content: "\e087";
}
.icon-alt:before {
  content: "\e088";
}
.icon-esc:before {
  content: "\e089";
}
.icon-bar-graph:before {
  content: "\e090";
}
.icon-bar-graph-2:before {
  content: "\e091";
}
.icon-pie-graph:before {
  content: "\e092";
}
.icon-star:before {
  content: "\e093";
}
.icon-arrow-left:before {
  content: "\e094";
}
.icon-arrow-right:before {
  content: "\e095";
}
.icon-arrow-up:before {
  content: "\e096";
}
.icon-arrow-down:before {
  content: "\e097";
}
.icon-volume:before {
  content: "\e098";
}
.icon-mute:before {
  content: "\e099";
}
.icon-content-right:before {
  content: "\e100";
}
.icon-content-left:before {
  content: "\e101";
}
.icon-grid:before {
  content: "\e102";
}
.icon-grid-2:before {
  content: "\e103";
}
.icon-columns:before {
  content: "\e104";
}
.icon-loader:before {
  content: "\e105";
}
.icon-bag:before {
  content: "\e106";
}
.icon-ban:before {
  content: "\e107";
}
.icon-flag2:before {
  content: "\e108";
}
.icon-trash:before {
  content: "\e109";
}
.icon-expand:before {
  content: "\e110";
}
.icon-contract:before {
  content: "\e111";
}
.icon-maximize:before {
  content: "\e112";
}
.icon-minimize:before {
  content: "\e113";
}
.icon-plus:before {
  content: "\e114";
}
.icon-minus:before {
  content: "\e115";
}
.icon-check:before {
  content: "\e116";
}
.icon-cross:before {
  content: "\e117";
}
.icon-move:before {
  content: "\e118";
}
.icon-delete:before {
  content: "\e119";
}
.icon-menu:before {
  content: "\e120";
}
.icon-archive:before {
  content: "\e121";
}
.icon-inbox:before {
  content: "\e122";
}
.icon-outbox:before {
  content: "\e123";
}
.icon-file:before {
  content: "\e124";
}
.icon-file-add:before {
  content: "\e125";
}
.icon-file-subtract:before {
  content: "\e126";
}
.icon-help:before {
  content: "\e127";
}
.icon-open:before {
  content: "\e128";
}
.icon-ellipsis:before {
  content: "\e129";
}
.icon-directions:before {
  content: "\e63c";
}
.icon-mail2:before {
  content: "\e63d";
}
.icon-reply2:before {
  content: "\e63e";
}
.icon-reply-all:before {
  content: "\e63f";
}
.icon-forward:before {
  content: "\e640";
}
.icon-vcard:before {
  content: "\e641";
}
.icon-export:before {
  content: "\e642";
}
.icon-location2:before {
  content: "\e643";
}
.icon-map3:before {
  content: "\e644";
}
.icon-compass2:before {
  content: "\e645";
}
.icon-heart3:before {
  content: "\e646";
}
.icon-heart4:before {
  content: "\e647";
}
.icon-star2:before {
  content: "\e648";
}
.icon-star3:before {
  content: "\e649";
}
.icon-thumbsup:before {
  content: "\e64a";
}
.icon-thumbsdown:before {
  content: "\e64b";
}
.icon-chat2:before {
  content: "\e64c";
}
.icon-comment:before {
  content: "\e64d";
}
.icon-quote:before {
  content: "\e64e";
}
.icon-house:before {
  content: "\e64f";
}
.icon-popup:before {
  content: "\e650";
}
.icon-search3:before {
  content: "\e651";
}
.icon-bell2:before {
  content: "\e652";
}
.icon-link2:before {
  content: "\e653";
}
.icon-flag:before {
  content: "\e654";
}
.icon-cog2:before {
  content: "\e655";
}
.icon-tools3:before {
  content: "\e656";
}
.icon-trophy2:before {
  content: "\e657";
}
.icon-tag2:before {
  content: "\e658";
}
.icon-camera3:before {
  content: "\e659";
}
.icon-megaphone:before {
  content: "\e65a";
}
.icon-new:before {
  content: "\e65b";
}
.icon-graduation:before {
  content: "\e65c";
}
.icon-book2:before {
  content: "\e65d";
}
.icon-newspaper2:before {
  content: "\e65e";
}
.icon-clock3:before {
  content: "\e65f";
}
.icon-bolt:before {
  content: "\e660";
}
.icon-thunder:before {
  content: "\e661";
}
.icon-air:before {
  content: "\e662";
}
.icon-language:before {
  content: "\e663";
}
.icon-battery2:before {
  content: "\e664";
}
.icon-bucket:before {
  content: "\e665";
}
.icon-earth:before {
  content: "\e666";
}
.icon-progress-3:before {
  content: "\e667";
}
.icon-progress-2:before {
  content: "\e668";
}
.icon-brogress-1:before {
  content: "\e669";
}
.icon-progress-0:before {
  content: "\e66a";
}
.icon-code:before {
  content: "\e66b";
}
.icon-light-bulb:before {
  content: "\e66c";
}
.icon-clipboard3:before {
  content: "\e66d";
}
.icon-cart:before {
  content: "\e66e";
}
.icon-box2:before {
  content: "\e66f";
}
.icon-minus2:before {
  content: "\e670";
}
.icon-plus2:before {
  content: "\e671";
}
.icon-cross2:before {
  content: "\e672";
}
.icon-minus3:before {
  content: "\e673";
}
.icon-plus3:before {
  content: "\e674";
}
.icon-cross3:before {
  content: "\e675";
}
.icon-minus4:before {
  content: "\e676";
}
.icon-plus4:before {
  content: "\e677";
}
.icon-erase:before {
  content: "\e678";
}
.icon-blocked:before {
  content: "\e679";
}
.icon-info2:before {
  content: "\e67a";
}
.icon-help2:before {
  content: "\e67b";
}
.icon-warning:before {
  content: "\e67c";
}
.icon-cycle:before {
  content: "\e67d";
}
.icon-cw:before {
  content: "\e67e";
}
.icon-ccw:before {
  content: "\e67f";
}
.icon-shuffle2:before {
  content: "\e680";
}
.icon-history:before {
  content: "\e681";
}
.icon-text:before {
  content: "\e682";
}
.icon-text2:before {
  content: "\e683";
}
.icon-cloud3:before {
  content: "\e684";
}
.icon-upload3:before {
  content: "\e685";
}
.icon-book3:before {
  content: "\e686";
}
.icon-arrow-left2:before {
  content: "\e606";
}
.icon-arrow-down2:before {
  content: "\e607";
}
.icon-arrow-up2:before {
  content: "\e608";
}
.icon-arrow-right2:before {
  content: "\e609";
}
.icon-arrow-left3:before {
  content: "\e60a";
}
.icon-arrow-down3:before {
  content: "\e60c";
}
.icon-arrow-up3:before {
  content: "\e60d";
}
.icon-arrow-right3:before {
  content: "\e60e";
}
.icon-arrow-left4:before {
  content: "\e612";
}
.icon-arrow-down4:before {
  content: "\e61a";
}
.icon-arrow-up4:before {
  content: "\e61b";
}
.icon-arrow-right4:before {
  content: "\e622";
}
.icon-arrow-left5:before {
  content: "\e623";
}
.icon-arrow-down5:before {
  content: "\e624";
}
.icon-arrow-up5:before {
  content: "\e625";
}
.icon-uniE626:before {
  content: "\e626";
}
.icon-ellipsis2:before {
  content: "\e627";
}
.icon-dots:before {
  content: "\e628";
}
.icon-dot:before {
  content: "\e629";
}
.icon-twitter:before {
  content: "\e62a";
}
.icon-twitter2:before {
  content: "\e62b";
}
.icon-facebook:before {
  content: "\e62c";
}
.icon-facebook2:before {
  content: "\e62d";
}
.icon-facebook3:before {
  content: "\e62e";
}
.icon-googleplus:before {
  content: "\e62f";
}
.icon-googleplus2:before {
  content: "\e630";
}
.icon-pinterest:before {
  content: "\e631";
}
.icon-pinterest2:before {
  content: "\e632";
}
.icon-tumblr:before {
  content: "\e633";
}
.icon-tumblr2:before {
  content: "\e634";
}
.icon-linkedin:before {
  content: "\e635";
}
.icon-linkedin2:before {
  content: "\e636";
}
.icon-dribbble:before {
  content: "\e637";
}
.icon-dribbble2:before {
  content: "\e638";
}
.icon-google:before {
  content: "\e639";
}
.icon-bing:before {
  content: "\e63a";
}
.icon-yahoo:before {
  content: "\e63b";
}
.icon-mobile2:before {
  content: "\e00a";
}
.icon-laptop:before {
  content: "\e00b";
}
.icon-desktop2:before {
  content: "\e00c";
}
.icon-tablet:before {
  content: "\e00d";
}
.icon-phone:before {
  content: "\e00e";
}
.icon-document:before {
  content: "\e00f";
}
.icon-documents:before {
  content: "\e01a";
}
.icon-search:before {
  content: "\e01b";
}
.icon-clipboard2:before {
  content: "\e01c";
}
.icon-newspaper:before {
  content: "\e01d";
}
.icon-browser:before {
  content: "\e01e";
}
.icon-calendar:before {
  content: "\e01f";
}
.icon-presentation:before {
  content: "\e02a";
}
.icon-picture:before {
  content: "\e02b";
}
.icon-pictures:before {
  content: "\e02c";
}
.icon-video2:before {
  content: "\e02d";
}
.icon-camera2:before {
  content: "\e02e";
}
.icon-toolbox:before {
  content: "\e02f";
}
.icon-briefcase2:before {
  content: "\e03a";
}
.icon-wallet:before {
  content: "\e03b";
}
.icon-gift:before {
  content: "\e03c";
}
.icon-bargraph:before {
  content: "\e03d";
}
.icon-expand2:before {
  content: "\e03e";
}
.icon-edit:before {
  content: "\e03f";
}
.icon-adjustments:before {
  content: "\e04a";
}
.icon-ribbon2:before {
  content: "\e04b";
}
.icon-hourglass:before {
  content: "\e04c";
}
.icon-trophy:before {
  content: "\e04d";
}
.icon-map2:before {
  content: "\e04e";
}
.icon-puzzle:before {
  content: "\e04f";
}
.icon-basket:before {
  content: "\e05a";
}
.icon-envelope:before {
  content: "\e05b";
}
.icon-streetsign:before {
  content: "\e05c";
}
.icon-telescope:before {
  content: "\e05d";
}
.icon-gears:before {
  content: "\e05e";
}
.icon-key:before {
  content: "\e05f";
}
.icon-paperclip:before {
  content: "\e06a";
}
.icon-attachment:before {
  content: "\e06b";
}
.icon-pricetags:before {
  content: "\e06c";
}
.icon-lightbulb:before {
  content: "\e06d";
}
.icon-layers2:before {
  content: "\e06e";
}
.icon-pencil:before {
  content: "\e06f";
}
.icon-tools2:before {
  content: "\e07a";
}
.icon-tools-2:before {
  content: "\e07b";
}
.icon-magnifying-glass:before {
  content: "\e07c";
}
.icon-linegraph:before {
  content: "\e07d";
}
.icon-caution:before {
  content: "\e07e";
}
.icon-recycle:before {
  content: "\e07f";
}
.icon-profile-male:before {
  content: "\e08a";
}
.icon-profile-female:before {
  content: "\e08b";
}
.icon-genius:before {
  content: "\e08c";
}
.icon-map-pin:before {
  content: "\e08d";
}
.icon-chat:before {
  content: "\e08e";
}
.icon-heart2:before {
  content: "\e08f";
}
.icon-cloud2:before {
  content: "\e09a";
}
.icon-upload2:before {
  content: "\e09b";
}
.icon-download2:before {
  content: "\e09c";
}
.icon-target2:before {
  content: "\e09d";
}
.icon-piechart:before {
  content: "\e09e";
}
.icon-speedometer:before {
  content: "\e09f";
}
.icon-global:before {
  content: "\e0a0";
}
.icon-compass:before {
  content: "\e0a1";
}
.icon-lifesaver:before {
  content: "\e0a2";
}
.icon-clock2:before {
  content: "\e0a3";
}
.icon-refresh:before {
  content: "\e0a4";
}
.icon-happy:before {
  content: "\e0a5";
}
.icon-sad:before {
  content: "\e0a6";
}
.iconized-left,
header .navigation li:before,
.new-entries h2:before,
.also-new h2:before,
.domain-listing h2:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconized-right,
.website-box h3:after,
.website-details .open-site:after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 0.5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: underline;
}
h2 {
  margin-bottom: 1.2em;
}
header {
  background: white;
  padding-top: 50px;
  padding-bottom: 50px;
}
header .branding h1 {
  font-size: 48px;
  font-size: 3rem;
  color: #222965;
  margin: 0px;
}
header .branding h1 small {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: normal;
  color: #cbcbcb;
  white-space: nowrap;
}
header .branding h1 a {
  text-decoration: none;
}
header .branding h1 a:hover {
  color: black;
}
header .navigation li {
  margin-top: 20px;
}
header .navigation li:before {
  font-family: "custom-icons";
  content: "\e64f";
}
.new-entries .intro {
  font-size: 1.4em;
  font-family: "Raleway", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  margin: 4em 0em 3em;
}
.new-entries h2:before {
  font-family: "custom-icons";
  content: "\e037";
}
.website-box {
  margin-bottom: 30px;
  height: 400px;
}
.website-box .date {
  background: #cbcbcb;
  color: #444444;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 5px;
}
.website-box h3 {
  margin: 0px 0px 10px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.website-box h3:after {
  font-family: "custom-icons";
  content: "\e128";
}
.website-box h3:after {
  color: #666666;
  font-size: 0.5em;
}
.website-box h3:before {
  top: 0em;
}
.website-box a {
  text-decoration: none;
}
.website-box a:hover {
  text-decoration: underline;
}
.website-box .website-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #444444;
  margin-bottom: 0.2em;
}
.website-box .website-description {
  font-weight: normal;
  color: #666666;
  font-size: 0.95em;
}
.thumbnail {
  border: 10px solid #222965;
  margin: 0px 0px 10px 0px;
  overflow: hidden;
}
.thumbnail img {
  width: 100%;
  margin-bottom: -5px;
}
.also-new {
  padding-bottom: 80px;
}
.also-new h2:before {
  font-family: "custom-icons";
  content: "\e125";
}
.also-new li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7em;
}
.domain-listing {
  padding-bottom: 80px;
}
.domain-listing h2:before {
  font-family: "custom-icons";
  content: "\e01b";
}
.domain-listing li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7em;
}
.website-details {
  margin: 80px 0px 80px 0px;
}
.website-details .open-site {
  font-weight: normal;
}
.website-details .open-site:after {
  font-family: "custom-icons";
  content: "\e128";
}
.website-details h1 small {
  margin-left: 10px;
}
.website-details h2 {
  margin: 10px 0px 20px 0px;
}
.website-details .thumbnail {
  width: 400px;
}
.pagination {
  margin-bottom: 40px;
}
.pagination ul {
  padding: 10px 0px;
}
.pagination li {
  color: #666666;
  text-transform: uppercase;
}
.pagination li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  display: block;
  line-height: 20px;
  width: 20px;
  text-align: center;
}
.pagination li a:hover {
  background: #ededed;
}
.pagination li.previous {
  margin-right: 10px;
}
.pagination li.previous a {
  width: auto;
  padding: 0px 10px;
}
.pagination li.next {
  margin-left: 10px;
}
.pagination li.next a {
  width: auto;
  padding: 0px 10px;
}
.pagination li.active {
  background: white;
}
.letter-navigation {
  background: #444444;
}
.letter-navigation ul {
  padding: 10px 0px;
}
.letter-navigation li {
  display: inline-block;
  color: #cbcbcb;
  text-transform: uppercase;
}
.letter-navigation li.first {
  margin-right: 20px;
  margin-left: 20px;
}
.letter-navigation li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  display: block;
  line-height: 20px;
  width: 20px;
  text-align: center;
}
.letter-navigation li a:hover {
  background: #666666;
}
footer {
  background: white;
  padding: 25px 0px;
  margin: 0px 0px 0px 0px;
}
footer p {
  margin: 0px;
}
.not-found .row {
  padding: 100px 0px;
}
